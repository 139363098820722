// src/components/LanguageSwitcher.js

import React from 'react';
import { useTranslation } from 'react-i18next';
 
const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  // Function to toggle between German and English
  const toggleLanguage = () => {
    const newLang = i18n.language === 'de' ? 'en' : 'de';
    i18n.changeLanguage(newLang);
  };

  // Determine the flag image based on the current language
  const flagImg =
    i18n.language === 'de'
      ? require('../images/ukflag.png')
      : require('../images/germanyflag.png');

  return (
    <div
      className="absolute z-[99] max-sm:top-[10px] max-sm:right-[11px] sm:top-[20px] sm:right-[28px] max-sm:w-[31px] max-sm:h-[31px] sm:w-[50px] sm:h-[50px] flex justify-center items-center max-sm:p-[2px] sm:p-[4px] rounded-[8px] bg-lightwhite cursor-pointer transition-transform transform hover:scale-105"
      onClick={toggleLanguage}
      title={i18n.language === 'de' ? 'Switch to English' : 'Wechsel zu Deutsch'}
    >
      <img src={flagImg} alt="flag" className="rounded-[4px] w-full h-full object-contain" />
    </div>
  );
};

export default LanguageSwitcher;
