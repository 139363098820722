// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translations } from './pages/data'; // Import from pages folder

// Retrieve the saved language from localStorage, default to 'de' (German)
const savedLanguage = localStorage.getItem('language') || 'de';

i18n
  .use(initReactI18next) // Connects i18n with React
  .init({
    resources: translations, // Use translations from data.js
    lng: savedLanguage, // Set default language
    fallbackLng: 'en', // Fallback to English if translation not found
    keySeparator: '.', // Use dot notation for nested keys
    interpolation: {
      escapeValue: false, // React already protects from XSS
    },
  });

// Persist language changes to localStorage
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});

export default i18n;
