export const translations = {

    // banner
    bannerimg: require('../images/banner.png'),
    bannermobileimg: require('../images/mobilebanner.png'),
    bannervectorimg: require('../images/bannervector.svg').default,
    playicon: require('../images/playicon.svg').default,
    ukflagimg: require('../images/ukflag.png'),
    bannermobilevectorimg: require('../images/bannermobilevector.svg').default,

    // footer
    footerimg: require('../images/footerimg.jpg'),
    footermobileimg: require('../images/footermobileimg.jpg'),
    footervector: require('../images/footervector.svg').default,
    footervectormobile: require('../images/footermobilevector.svg').default,

    en: {
        translation: {
            banner: {
                mainheading: "My Illegal Life",
                playvideo: "Play trailer",
            },
            "life": [
                {
                    picture: require('../images/Picture1.png'),
                    backgroundImage: require('../images/bg1.png'),
                    mobileBackgroundImage: require('../images/bg1mobile.png'),
                    "lifeinfo": '"The city was sleeping, it was icy cold and snowing incessantly. We brought nothing with us, apart from the doll’s wagon containing my child’s few possessions. First we stopped at the Eberts’ in Schönhauser Allee.“',
                    "lifesmallinfo": "From Hella Zacharias´s report to the restitution authority"
                },
                {
                    picture: require('../images/Picture2.png'),
                    backgroundImage: require('../images/bg2mobile.png'),
                    mobileBackgroundImage: require('../images/bg2mobile.png'),
                    lifetitle: 'Winter 1942',
                    lifeinfo: 'Hella Zacharias and her daughter Hannelore go underground to avoid deportation.',
                },
                {
                    picture: require('../images/Picture3.png'),
                    lifetitle: 'Hidden',
                    lifeinfo: 'Hannelore’s father, Felix Zacharias, goes into hiding alone. For years, he doesn’t know where his wife and daughter are.',
                    backgroundImage: require('../images/bg3.png'),
                    mobileBackgroundImage: require('../images/bg3mobile.png'),
                },
                {
                    picture: require('../images/Picture4.png'),
                    lifetitle: 'Under a false name',
                    lifeinfo: 'In 1944, Hella and her daughter live under false names. The railway worker Fritz Kittel hides them in his house.',
                    backgroundImage: require('../images/bg4.png'),
                    mobileBackgroundImage: require('../images/bg4mobile.png'),
                },
                {
                    picture: require('../images/Picture5.png'),
                    lifetitle: 'Survival',
                    lifeinfo: 'A Reichsbahn ID is forged. The rescue is successful.',
                    backgroundImage: require('../images/bg5.png'),
                    mobileBackgroundImage: require('../images/bg5mobile.png'),
                },
                {
                    picture: require('../images/Picture6.png'),
                    lifetitle: 'The search',
                    lifeinfo: 'Hella’s and Hannelore’s children want to know how their family was saved. That’s where the film begins.',
                    backgroundImage: require('../images/bg6.png'),
                    mobileBackgroundImage: require('../images/bg6mobile.png'),
                },
            ],
            "accordion": {
                "noData": "No Data Available",
                "items": [
                    {
                        "title": "About the Film",
                        "content": [
                            'When Hella Zacharias received the deportation order from the Gestapo in 1941, she went underground with her 5-year-old daughter Hannelore. In 1944, Hella met the railway worker Fritz Kittel. While his employer, the Reich railway, sent millions of people to the death camps, Fritz decided to help them survive by pretending they were his wife and daughter. After the war, Fritz Kittel never told his family what he did for Hella and Hannelore. Today, Esther Dischereit, possibly the most important German-Jewish author of the post-Shoah generation, is trying to find out what really happened between her mother Hella and Fritz Kittel.',
                        ]
                    },
                    {
                        title: "Background",
                        content: [
                            'Berlin, winter 1942: The Gestapo’s deportation order has already been delivered. But Hella Zacharias doesn’t intend to comply. Instead, she packs a large suitcase, one that isn’t too conspicuous, takes her five-year-old daughter Hannelore by the hand, and goes underground. In the coming weeks, she will hide from Nazi persecution with well-meaning acquaintances, helpers, in cellars and in parks. Again and again, informants report the mother and daughter, and they must scramble to find a new hiding place.',

                            'Sometime in 1944, Hella’s flight takes an unexpected turn: she meets the freight conductor Fritz Kittel. The Reich Railway employee, whose employer is transporting millions to the extermination camps, hides Hella and Hannelore in his house in Sorau, now Żary, Poland. After three years of relentless rushing from place to place, mother and daughter can catch their breath a bit. But the days in hiding with Fritz Kittel are also precarious – and dangerous. Hella must hide in the attic during the day. Hannelore can go to school, but the adults live in constant fear that the child will blurt something out or that they will be exposed by an informer.',

                            'Late in 1944, the Red Army is approaching Sorau. Fritz Kittel is transferred to Heringen in Hessen. Hella wants to wait for the arrival of the Russians; as a persecuted Jew, she has nothing to fear. But Kittel persuades her to travel with him to Hessen, in the heart of the collapsing Third Reich. Hella, Hannelore, and Kittel board the last train leaving Sorau.',

                            'After several days’ journey under heavy bombardment, the train finally reaches Heringen in February 1945. Here Fritz Kittel has Hella and Hannelore registered as his wife and daughter. Now they live openly among the German population who, at least officially, are anticipating miracle weapons and final victory, while Hella yearns for the arrival of the Allies.',

                            'And Hella’s hopes are fulfilled. In April 1945, the U.S. Army enters Heringen. Hella and Hannelore have escaped Nazi persecution. Her parents, Rosa and Berthold Freundlich, and her husband, Felix Zacharias, have also managed to survive in hiding. Many in the Freundlich and Zacharias families have been murdered.',

                            'Esther Dischereit, daughter of Hella Zacharias from a second marriage after the war, is possibly the most important German-Jewish author of the post-Shoah generation. She has spent her life telling the story in novels, radio plays, political essays, poems. Yet resonating through all of her work is the realization that a full understanding of the lives of people who experienced the Nazi period will always remain elusive.',

                            '“Before or after – that makes a difference. We are from the same family, but my mother and older sister were before. Before: that is another world. I never managed to form an image of that world. Everyone who had been at home in that world was putting aside the fragments. And in each case, pieces were missing, hundreds of them.”',

                            'Esther, born in 1952, grew up witnessing the traumas of her mother, Hella. She herself writes about the children of the survivors: “They are the ones who heard their parents sigh and scream at night when the dreams came back.”',
                            'Chana Freundlich, daughter of Esther, granddaughter of Hella, says: “It’s clear to me that the trauma lives on. Where should it end?”',

                            'In Esther Dischereit’s childhood, there was always talk of surviving underground. For the most part, these were remarks in passing, a sentence here or there, fragments, tropes. The name Fritz Kittel comes up too, again and again, though Hella never once tells the story of her rescue from beginning to end.',

                            'It took a long time before Esther resolved to systematically research the history of her family’s persecution. She says: “Before I didn’t want to know all the details. I didn’t want to find more dead.”',

                            'At some point, many years after her mother’s death, the desire arises in Esther to find railway worker Fritz Kittel, the man who saved her mother and older sister, and thank him.',

                            'The search begins, and this is the point where the film starts, putting together the missing pieces.',
                        ],
                    },
                    {
                        title: "Screenings",
                        images: [
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                        ],
                    },
                ]
            },
            "crew": {
                "title": "Crew",
                left: [
                    { info: 'DIRECTOR', infobold: 'Gerhard Schick' },
                    { info: 'SCRIPT', infobold: 'Esther Dischereit' },
                    { info: 'DIRECTORS OF PHOTOGRAPHY', infobold: 'Linda Schefferski, Gerhard Schick, Miriam Jakobs' },
                    { info: 'SOUND', infobold: 'Jule Buerjes, Filip Forberg, Artem Funk' },
                    { info: 'FILM EDITING', infobold: 'Gerhard Schick' },
                ],
                right: [
                    { info: 'MUSIC', infobold: 'Markus Aust' },
                    { info: 'PRODUCED BY', infobold: 'Film und Kontext' },
                    { info: 'SUPPORTED BY', infobold: 'Deutsche Bahn AG, CFS Consultancy Christian Kraft, NS-Dokumentationszentrum Köln' },
                ],
                "playvideo": "Play trailer",
                playicon: require('../images/playicon.svg').default,
            },
            footer: {
                contacttitle: 'Kontakt',
                contactaddress: 'Jakobs & Schick GbR -FILM UND KONTEXT- Sechzigstraße 13a 50733 Köln',
                footerinfo: 'Web Design & Realisation:',
                linkinfo: 'ADPACE',
                linktitle1: 'Email',
                linkinfo1: 'gerhard.schick@filmundkontext.de',
                linktitle2: 'Webseite',
                linkinfo2: 'www.filmundkontext.de',
            },
        },
    },
    de: {
        translation: {
            banner: {
                mainheading: "Mein illegales Leben",
                playvideo: "Trailer abspielen",
            },
            "life": [
                {
                    picture: require('../images/Picture1.png'),
                    backgroundImage: require('../images/bg1.png'),
                    mobileBackgroundImage: require('../images/bg1mobile.png'),
                    "lifeinfo": '"Die Stadt schlief, es war eisig kalt und unablässig schneite es. Nichts, außer dem Puppenwagen, der wenige Habseligkeiten meines Kindes barg, führten wir mit uns. Zuerst machten wir bei Ebert, Schönhauser Allee, halt.“',
                    "lifesmallinfo": "Aus Hella Zacharias ́ Bericht an die Entschädigungsbehörde"
                },
                {
                    picture: require('../images/Picture2.png'),
                    backgroundImage: require('../images/bg2mobile.png'),
                    mobileBackgroundImage: require('../images/bg2mobile.png'),
                    lifetitle: 'Winter 1942',
                    lifeinfo: 'Hella Zacharias taucht mit ihrer Tochter nach dem Deportationsbescheid der Gestapo unter.',
                },
                {
                    picture: require('../images/Picture3.png'),
                    lifetitle: 'Versteckt',
                    lifeinfo: 'Hannelores Vater, Felix Zacharias, taucht alleine unter. Er weiß nicht, wo seine Tochter und seine Frau sich befinden.',
                    backgroundImage: require('../images/bg3.png'),
                    mobileBackgroundImage: require('../images/bg3mobile.png'),
                },
                {
                    picture: require('../images/Picture4.png'),
                    lifetitle: 'Unter falschem Namen',
                    lifeinfo: '1944 leben Hella und ihre Tochter unter falschem Namen. Der Bahnarbeiter Fritz Kittel versteckt sie und gibt das Mädchen als seine Nichte aus.',
                    backgroundImage: require('../images/bg4.png'),
                    mobileBackgroundImage: require('../images/bg4mobile.png'),
                },
                {
                    picture: require('../images/Picture5.png'),
                    lifetitle: 'Rettung',
                    lifeinfo: 'Mit Hilfe eines gefälschten Reichsbahnausweises gelingt die Rettung',
                    backgroundImage: require('../images/bg5.png'),
                    mobileBackgroundImage: require('../images/bg5mobile.png'),
                },
                {
                    picture: require('../images/Picture6.png'),
                    lifetitle: 'Die Suche',
                    lifeinfo: 'Die Nachfahren von Hella und Hannelore wollen endlich wissen, wer der Retter ihrer Familie war. Mit dieser Suche beginnt der Film.',
                    backgroundImage: require('../images/bg6.png'),
                    mobileBackgroundImage: require('../images/bg6mobile.png'),
                },
            ],
            "accordion": {
                "noData": "Keine Daten verfügbar",
                "items": [
                    {
                        "title": "Über den Film",
                        "content": [
                            'Winter 1942: Hella Zacharias hält den Deportationsbescheid der Gestapo in den Händen. Sie nimmt ihren ganzen Mut zusammen und taucht mit ihrer 5-jährigen Tochter Hannelore in den Berliner Untergrund ab.',

                            'In den kommenden Monaten wird sie sich bei wohlwollenden Bekannten, Helfern, in Kellern und in Parks vor der Verfolgung der Nazis verstecken. Immer wieder werden Mutter und Tochter denunziert und müssen Hals über Kopf das Versteck wechseln.',

                            '1944 leben sie unter falschem Namen in einem Haus in Sorau / Żary im heutigen Polen. Im selben Haus wohnt der Bahnarbeiter Fritz Kittel. Während sein Arbeitgeber, die Deutsche Reichsbahn, Millionen in den Tod schickt, trifft Fritz Kittel eine mutige Entscheidung: Er versteckt Hella in seiner Wohnung und gibt ihre Tochter Hannelore als seine Nichte aus. Die Rettung gelingt.',

                            'Nach dem Krieg bekommt Hella Zacharias ein weiteres Kind: Esther Dischereit. Sie gilt heute als eine der bedeutendsten deutsch-jüdischen Schriftstellerinnen nach dem Holocaust. In ihrer Kindheit hat Esther immer wieder den Namen Fritz Kittel gehört. Doch sie ist ihm nie begegnet und wusste nicht, was aus ihm geworden ist.',

                            'Schließlich entscheidet sie sich, gemeinsam mit ihrer Tochter Chana, dass sie endlich wissen will, was aus dem Retter ihrer Familie geworden ist. Mit dieser Suche beginnt der Film, eine Suche nach den fehlenden Splittern der Familiengeschichte und der eigenen Identität.',
                        ]
                    },
                    {
                        title: "Hintergrund",
                        content: [
                            'Winter 1942: Hella Zacharias hält den Deportationsbescheid der Gestapo in den Händen. Doch sie wird nicht zum Bahnhof gehen. Sie nimmt ihren ganzen Mut zusammen und taucht mit ihrer 5-jährigen Tochter Hannelore in den Berliner Untergrund ab.In den kommenden Monaten wird sie sich bei wohlwollenden Bekannten, Helfern, in Kellern und in Parks vor der Verfolgung der Nazis verstecken. Hella nutzt mehrere Decknamen. Immer wieder werden Mutter und Tochter denunziert und müssen Hals über Kopf das Versteck wechseln.',

                            'Hella Zacharias schafft das Unmögliche: aus dem Versteck heraus gelingt es ihr, neben sich selbst und ihrer Tochter auch noch ihre ebenfalls untergetauchten Eltern zu versorgen.',

                            '1944 lebt sie unter falschem Namen im Lazarettweg 1 in einem Haus in Sorau / Żary im heutigen Polen. Doch ein Denunziant ist ihr auf den Fersen, er fordert sie auf, ihre Papiere vorzuzeigen. Im selben Haus wohnt der Bahnarbeiter Fritz Kittel. Während sein Arbeitgeber, die Deutsche Reichsbahn, Millionen in den Tod schickt, trifft Fritz Kittel eine mutige Entscheidung: Er versteckt Hella in seiner Wohnung und gibt ihre Tochter Hannelore als seine Nichte aus.',

                            'Im Januar 1945 rückt die rote Armee näher. Hella will sich als verfolgte Jüdin befreien lassen, doch Fritz Kittel überzeugt sie, mit ihm den letzten Zug zu besteigen – ins „Reich“. Er wird nach Hessen versetzt, dort läuft noch Rüstungsproduktion. Nach mehrtägiger Fahrt und heftigem Bombenbeschuss erreichen Fritz, Hella und Hannelore schließlich den kleinen Ort Heringen. Fritz Kittel geht dort zum Amt und lässt Hella als seine Frau und Hannelore als seine Tochter eintragen. Nun leben sie mit falscher Identität unter der deutschen Bevölkerung, in der man, zumindest offiziell, auf den Endsieg hofft, während Hella die Ankunft der Alliierten herbeisehnt. Der verwegene Plan gelingt. Im April 1945 werden sie von der amerikanischen Armee befreit.',

                            'Nach dem Krieg bekommt Hella Zacharias ein weiteres Kind: Esther Dischereit. Sie gilt heute als eine der wichtigsten literarischen deutsch-jüdischen Stimmen nach dem Holocaust. Ein Leben lang setzt sie sich mit der Geschichte in Romanen, Hörspielen, politischen Essays und Gedichten auseinander. Beim Eintauchen in die eigene Familiengeschichte findet sie Briefe wie diesen:',

                            '„Jetzt begann eine furchtbare Zeit, gehetzt wie ein Wild von einer Stelle zur anderen. Die Nächte brachten wir größtenteils auf Böden, Treppen, Luftschutzräumen und Eisenbahnwagen zu. Alles unangemeldet und so bin ich dann mit der Elektrischen um Berlin rumgefahren.”',

                            'Esther, 1952 geboren, wächst mit den Traumata ihrer Mutter auf. Sie schreibt über die Kinder der Überlebenden: „Es sind die, die das Seufzen und Schreien ihrer Eltern des Nachts mitanhörten, dann, wenn die Träume zurückkamen.“ Chana Freundlich, Esthers Tochter, sagt: „Es ist mir vollkommen klar, dass sich das Trauma weiterträgt. Wo soll es denn aufhören?“',

                            'Die Spurensuche führt sie auch in die Entschädigungsbehörde in Berlin, hier lagern Akten zu ihren Großeltern. Esther erfährt, dass es für einen Tag Aufenthalt in einem Konzentrationslager 5 DM Entschädigung gab. Der Tag der Einlieferung und der Tag der Hinrichtung wurden dabei nur als halber Tag gerechnet, an dem ein Anspruch auf Entschädigung besteht. 2 DM und 50 Pfennig.',

                            'In ihrer Kindheit hat Chana immer wieder den Namen Fritz Kittel gehört. Doch sie ist ihm nie begegnet und wusste nicht, was aus ihm geworden ist. Schließlich entscheiden sich Mutter und Tochter, dass sie endlich wissen wollen, was aus dem Retter ihrer Familie geworden ist. Mit dieser Suche beginnt der Film, eine Suche nach den fehlenden Splittern der Familiengeschichte und der eigenen Identität.',
                        ],
                    },
                    {
                        title: "Screenings",
                        images: [
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                            require('../images/screning.jpg'),
                        ],
                    },
                ]
            },
            "crew": {
                "title": "Mitwirkende",
                left: [
                    { info: 'REGIE', infobold: 'Gerhard Schick' },
                    { info: 'BUCH', infobold: 'Esther Dischereit' },
                    { info: 'KAMERA', infobold: 'Linda Schefferski, Gerhard Schick, Miriam Jakobs' },
                    { info: 'TON', infobold: 'Jule Buerjes, Filip Forberg, Artem Funk' },
                    { info: 'MONTAGE', infobold: 'Gerhard Schick' },
                ],
                right: [
                    { info: 'MUSIK', infobold: 'Markus Aust' },
                    { info: 'PRODUZIERT VON', infobold: 'Film und Kontext' },
                    { info: 'UNTERSTÜTZT VON', infobold: 'Deutsche Bahn AG, CFS Consultancy Christian Kraft, NS-Dokumentations-zentrum Köln' },
                ],
                "playvideo": "Trailer abspielen",
                playicon: require('../images/playicon.svg').default,
            },
            footer: {
                contacttitle: 'Kontakt',
                contactaddress: 'Jakobs & Schick GbR -FILM UND KONTEXT- Sechzigstraße 13a 50733 Köln',
                footerinfo: 'Web Design & Realisation:',
                linkinfo: 'ADPACE',
                linktitle1: 'Email',
                linkinfo1: 'gerhard.schick@filmundkontext.de',
                linktitle2: 'Webseite',
                linkinfo2: 'www.filmundkontext.de',
            },
        },
    },
};