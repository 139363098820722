import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LanguageSwitcher from './components/LanguageSwitcher';

function App() {
  return (
    <>
      <Router>
        <LanguageSwitcher />
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

