import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Fancybox } from '@fancyapps/ui'; // Import Fancybox
import '@fancyapps/ui/dist/fancybox.css'; // Import Fancybox CSS
import lifevector from '../images/lifevector.svg';
import lifemobilevector from '../images/lifemobilevector.svg';
import crewvector from '../images/crewvector.svg';
import crewmobilevector from '../images/crewmobilevector.svg';
import accordionarrow from '../images/accordionarrow.svg'; 

const Accordion = () => {
    const { t } = useTranslation();
    const accordionData = t('accordion.items', { returnObjects: true });
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="bg-[#0B2D3B] max-md:pt-[53px] md:pt-[75px] lg:pt-[100px] max-sm:pb-[60px] sm:pb-[75px] md:pb-[85px] lg:pb-[110px] relative max-sm:mt-[-2px]">
            <img src={lifevector} alt="vector" className='sm:block max-sm:hidden absolute w-full top-0' />
            <img src={lifemobilevector} alt="vector" className='max-sm:block sm:hidden absolute w-full top-0' />
            <img src={crewvector} alt="crewvector" className='sm:block max-sm:hidden w-full absolute bottom-0' />
            <img src={crewmobilevector} alt="crewvector" className='max-sm:block sm:hidden w-full absolute bottom-0' />
            <div className='container'>
                <div className='w-full max-w-[800px] mx-auto'>
                    {Array.isArray(accordionData) && accordionData.length > 0 ? (
                        accordionData
                        .filter(item => item.title !== "Screenings") // Filter to exclude Screenings
                        .map((item, index) => (
                            <AccordionItem
                                key={index}
                                index={index}
                                title={item.title}
                                content={item.content}
                                isActive={activeIndex === index}
                                onClick={() => toggleAccordion(index)}
                                images={item.images} // Pass images if you have them
                            />
                        ))
                    ) : (
                        <p className="text-[#F9FDFF] text-center">{t('accordion.noData')}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

const AccordionItem = ({ title, content, isActive, onClick, images }) => {
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState('0px');

    useEffect(() => {
        const handleResize = () => {
            if (isActive) {
                setContentHeight(`${contentRef.current.scrollHeight}px`);
            } else {
                setContentHeight('0px');
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isActive]);

    // Initialize Fancybox when the component renders
    useEffect(() => {
        Fancybox.bind("[data-fancybox]", {
            infinite: false, // Optional Fancybox settings
        });

        return () => {
            Fancybox.destroy();
        };
    }, []);

    return (
        <div className='border-b-solid border-b-lightwhiteborder border-b-[1px]'>
            <button
                onClick={onClick}
                className="w-full flex justify-between items-center max-md:py-[12px] md:py-[16px] focus:outline-none"
            >
                <span className='text-[#F9FDFF] font-roboto max-md:text-[24px] md:text-[28px] lg:text-[32px] font-bold leading-[125%]'>{title}</span>
                <img
                    src={accordionarrow}
                    alt="arrow"
                    className={`max-md:w-[12px] max-md:h-[12px] md:w-[20px] md:h-[20px] transition-transform duration-300 ${isActive ? 'rotate-180' : 'rotate-0'}`}
                />
            </button>
            <div
                ref={contentRef}
                style={{ height: contentHeight }}
                className="overflow-hidden transition-height duration-500 ease-in-out"
            >
                <div>
                    {Array.isArray(content) && content.length > 0 ? (
                        content.map((paragraph, index) => (
                            <p key={index} className={`text-[#F9FDFF] font-roboto max-md:text-[14px] md:text-[16px] font-normal leading-[150%] tracking-[0.5px] ${index === content.length - 1 ? 'mb-[16px]' : 'mb-[25px]'}`}>
                                {paragraph}
                            </p>
                        ))
                    ) : null}
                    {Array.isArray(images) && images.length > 0 ? (
                        <div className="grid max-md:grid-cols-2 md:grid-cols-4 max-md:gap-[16px] md:gap-[28px] lg:gap-[32px_40px] max-md:pb-[12px] md:pb-[20px]">
                            {images.map((imageSrc, index) => (
                                <a
                                    key={index}
                                    href={imageSrc} // Link to open in Fancybox
                                    data-fancybox="gallery" // Fancybox data attribute
                                    data-caption={`Screening Image ${index + 1}`} // Optional caption
                                >
                                    <img
                                        src={imageSrc}
                                        alt={`Screening Image ${index + 1}`}
                                        className="w-full border-[0.5px] border-[#FFF] border-solid"
                                    />
                                </a>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Accordion;



