import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from '../pages/data'; // Adjust the path to your translations file

const Life = () => { 
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 576);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Get life data from translations
    const lifeData = t('life', { returnObjects: true }); // Use returnObjects to get an array

    return (
        <div className='lifeblock max-sm:mt-[-2px]'> 
            {lifeData && lifeData.map((item, index) => (
                <div
                    key={index}
                    className={`flex items-center relative lifebg max-sm:min-h-[100%] max-sm:p-[40px_0px] ${index === 0 ? 'pb-[40px]' : 'p-[40px]'} ${index !== 0 ? 'min-h-[353px]' : ''}`}
                    style={{
                        backgroundImage: `url(${isMobile ? item.mobileBackgroundImage : item.backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '100%'
                    }}
                >
                    <div className='container'>
                        <div className={`flex justify-center items-center max-sm:gap-[15px] sm:gap-[40px] ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
                            <div>
                                <img src={item.picture} alt={t("banner.playvideo")} className='border-solid border-[3px] border-[#F9FDFF] shadow-custom rotate-[-2deg]' />
                            </div>
                            <div className='w-full max-sm:max-w-[100%] sm:max-w-[220px]'>
                                <div>
                                    <span className="text-[#2F2C2B] font-roboto text-[16px] font-bold leading-[150%] tracking-[0.5px]">{item.lifetitle}</span>
                                    <p className='text-[#2F2C2B] font-roboto max-sm:text-[14px] sm:text-[16px] font-normal leading-[150%] tracking-[0.5px]'>{item.lifeinfo}</p>
                                    <span className='block mt-[8px] text-[#2F2C2B] font-roboto text-[10px] font-normal leading-[12px] tracking-[0.4px]'>{item.lifesmallinfo}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Life;

