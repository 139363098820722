import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import the translation hook
import modalclosewhite from '../images/modalclosewhite.svg'

const Banner = ({ bannerimg, bannervectorimg, bannermobileimg, bannermobilevectorimg, playicon }) => {
  const { t, i18n } = useTranslation(); // Use the translation hook
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Video URL based on the current language
  const videoUrl = i18n.language === 'en' 
    ? 'https://player.vimeo.com/video/797639055' // Replace with the English video URL
    : 'https://player.vimeo.com/video/826496473'; // Replace with the German video URL

  return (
    <div className='relative'>
      <div className="bannerimg relative w-full h-full xl:h-[850px] xxl:h-[850px] xxxl:h-[1000px] before:absolute before:content-[''] before:bg-banner-gradient before:w-full before:h-full before:left-0 before:right-0 before:top-0 before:bottom-0">
        <img src={bannerimg} alt="banner" className='sm:block max-sm:hidden w-full h-full max-w-[100%] object-cover object-top' />
        <img src={bannermobileimg} alt="bannermobile" className='sm:hidden max-sm:block w-full h-full max-w-[100%] object-cover object-top' />
        <img src={bannervectorimg} alt="bannervector" className='sm:block max-sm:hidden w-full absolute left-0 right-0 bottom-0' />
        <img src={bannermobilevectorimg} alt="bannermobilevector" className='sm:hidden max-sm:block w-full absolute left-0 right-0 bottom-0' />
      </div>
      <div className='absolute w-full left-0 top-[50%] translate-y-[-50%]'>
        <div className='container'>
          <div>
            <div className='w-full max-sm:max-w-[180px] sm:max-w-[230px] md:max-w-[290px] lg:max-w-[332px]'>
              <h1 className='font-roboto text-[#F9FDFF] max-sm:text-[45px] sm:text-[56px] md:text-[70px] lg:text-[90px] font-bold leading-[111%] tracking-[-0.9px]'>
                {t('banner.mainheading')}
              </h1>
            </div>
            <div className='mt-[48px]'>
              <span
                onClick={openModal}
                className='inline-flex items-center justify-center p-[10px_16px] rounded-[8px] border-[0.5px] border-solid border-[#124E65] bg-[#F9FDFF] text-[#124E65] font-roboto text-[14px] font-normal leading-[142%] tracking-[0.25px] cursor-pointer hover:opacity-[0.7] transition-all duration-[0.3s]'
              >
                <img src={playicon} alt="play" className='mr-[6px]' />
                {t('banner.playvideo')}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Component */}
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <iframe
            src={videoUrl} // Dynamically set the video URL based on language
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Vimeo Video"
          ></iframe>
        </Modal>
      )}
    </div>
  );
};

// Modal component
const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="relative w-full max-sm:max-w-[90%] sm:max-w-[450px] md:max-w-[530px] lg:max-w-4xl mx-auto bg-white rounded-lg">
        <button onClick={onClose} className="absolute w-[20px] h-[20px] max-sm:top-[-30px] max-sm:right-[0px] sm:top-[3px] sm:right-[-30px] md:top-[0px] md:right-[-30px] lg:top-0 lg:right-[90px] text-[#FFF] text-[40px] font-bold cursor-pointer">
          <img src={modalclosewhite} alt="Close" className="w-full h-full" />
        </button>
        <div className="aspect-w-16 aspect-h-9 flex justify-center">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Banner;
