import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
 
const Footer = ({ footerimg, footervector, footermobileimg, footervectormobile }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className='relative'>
                <img src={footervector} alt="footervector" className='sm:block max-sm:hidden absolute w-full top-0 z-[9]' />
                <img src={footervectormobile} alt="footervectormobile" className='sm:hidden max-sm:block absolute w-full top-0 z-[9]' />
                <div className='footer-image w-full max-sm:h-full sm:h-[250px] md:h-[250px] lg:h-full footerimg relative'>
                    <img src={footerimg} alt="footerimg" className='sm:block max-sm:hidden w-full h-full object-cover max-w-[100%]' />
                    <img src={footermobileimg} alt="footermobileimg" className='sm:hidden max-sm:block w-full h-full object-cover max-w-[100%]' />
                </div>
                <div className='footer-block absolute left-0 top-[50%] max-sm:translate-y-[-35%] sm:translate-y-[-35%] md:translate-y-[-30%] lg:translate-y-[-25%] w-full'>
                    <div className='container'>
                        <div className='footer-content flex md:ml-[10px] max-sm:gap-[32px] sm:gap-[137px]'>
                            <div>
                                <div>
                                    <span className='block text-[#F9FDFF] font-roboto text-[12px] xl:text-[16px] font-bold leading-[133%] tracking-[0.5px]'>{t('footer.contacttitle')}</span>
                                    <div className='w-full max-w-[116px] xl:max-w-[170px]'>
                                        <p className='text-[#F9FDFF] font-roboto text-[11px] xl:text-[16px] font-medium leading-[145%] tracking-[0.5px]'>{t('footer.contactaddress')}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul className='flex flex-col gap-[16px]'>
                                    <li className='text-[#F9FDFF] font-roboto text-[12px] xl:text-[16px] font-bold leading-[133%] tracking-[0.5px]'>{t('footer.linktitle1')}<Link to='mailto:gerhard.schick@filmundkontext.de' className='block text-[11px] xl:text-[16px] font-medium leading-[145%] transition duration-[0.3s] hover:opacity-70'>{t('footer.linkinfo1')}</Link></li>
                                    <li className='text-[#F9FDFF] font-roboto text-[12px] xl:text-[16px] font-bold leading-[133%] tracking-[0.5px]'>{t('footer.linktitle2')}<Link to='https://filmundkontext.de/' target='_blank' className='block underline text-[11px] xl:text-[16px] font-medium leading-[145%] transition duration-[0.3s] hover:opacity-70'>{t('footer.linkinfo2')}</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='mt-[32px] md:ml-[10px]'>
                            <p className='text-[#F9FDFF] text-[11px] xl:text-[16px] font-roboto font-medium leading-[145%] tracking-[0.5px]'>{t('footer.footerinfo')} <Link to='https://adpace.de/' className='uppercase underline transition duration-[0.3s] hover:opacity-70' target='_blank'>{t('footer.linkinfo')}</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
