import React from 'react';
import Banner from '../components/Banner';
import { translations } from './data';
import Life from '../components/Life';
import Accordion from '../components/Accordion';
import Crew from '../components/Crew';
import Footer from '../components/Footer';
// Import other sections as needed

const Home = () => {
  return (
    <>
      <Banner {...translations}/>
      <Life {...translations}/>
      <Accordion {...translations}/>
      <Crew {...translations}/>
      <Footer {...translations}/>
    </>
  );
};

export default Home;

