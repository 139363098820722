import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import modalclosewhite from '../images/modalclosewhite.svg';

const Crew = () => {
    const { t, i18n } = useTranslation(); // i18n hinzugefügt

    const crew = t('crew', { returnObjects: true });

    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Dynamische Video-URL basierend auf der aktuellen Sprache
    const videoUrl = i18n.language === 'en' 
        ? 'https://player.vimeo.com/video/797639055' // Replace with the English video URL
        : 'https://player.vimeo.com/video/826496473'; // Replace with the German video URL
    return (
        <div className='py-[35px] relative'>
            <div className='container'>
                <div>
                    <div>
                        <h2 className='text-[#2F2C2B] text-center font-roboto text-[24px] sm:text-[32px] max-sm:font-semibold sm:font-bold leading-[125%]'>
                            {crew.title}
                        </h2>
                    </div>
                    <div className='max-sm:mt-[16px] sm:mt-[32px] max-sm:flex-col sm:flex gap-[8px]'>
                        {/* Left Column: Items from left array */}
                        <div className='w-full max-sm:max-w-[100%] sm:max-w-[50%] md:max-w-[482px]'>
                            {crew.left.map((item, index) => (
                                <div key={index} className='flex items-center p-[16px] rounded-[8px] bg-[#F1EDEA] mb-[8px]'>
                                    <p className='text-[#2F2C2B] font-roboto max-sm:text-[14px] sm:text-[16px] font-normal leading-[150%] tracking-[0.5px] uppercase'>
                                        {item.info}
                                        <span className='font-bold block normal-case'>{item.infobold}</span>
                                    </p>
                                </div>
                            ))}
                        </div>

                        {/* Right Column: Items from right array */}
                        <div className='w-full max-sm:max-w-[100%] sm:max-w-[50%] md:max-w-[330px]'>
                            {crew.right.map((item, index) => (
                                <div key={index} className={`flex items-center p-[16px] rounded-[8px] bg-[#F1EDEA] mb-[8px] ${index === crew.right.length - 1 ? 'right-last-item' : ''}`}>
                                    <p className='text-[#2F2C2B] font-roboto max-sm:text-[14px] sm:text-[16px] font-normal leading-[150%] tracking-[0.5px] uppercase'>
                                        {item.info}
                                        <span className='font-bold block normal-case'>{item.infobold}</span>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='max-sm:mt-[16px] sm:mt-[32px] flex justify-center'>
                        <span onClick={openModal} className='max-sm:flex max-sm:w-full sm:inline-flex items-center justify-center p-[10px_16px] rounded-[8px] border-[0.5px] border-solid border-[#124E65] bg-[#F9FDFF] text-[#124E65] font-roboto text-[14px] font-normal leading-[142%] tracking-[0.25px] cursor-pointer hover:opacity-[0.7] transition-all duration-[0.3s]'>
                            <img src={crew.playicon} alt="play" className='mr-[6px]' />
                            {crew.playvideo}
                        </span>
                    </div>
                </div>
            </div>
            {/* Modal Component */}
            {isModalOpen && (
                <Modal onClose={closeModal}>
                    <iframe
                        src={videoUrl} // Dynamische URL basierend auf Sprache
                        width="640"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        title="Vimeo Video"
                    ></iframe>
                </Modal>
            )}
        </div>
    );
};

// Modal component
const Modal = ({ children, onClose }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
            <div className="relative w-full max-sm:max-w-[90%] sm:max-w-[450px] md:max-w-[530px] lg:max-w-4xl mx-auto bg-white rounded-lg">
                <button onClick={onClose} className="absolute w-[20px] h-[20px] max-sm:top-[-30px] max-sm:right-[0px] sm:top-[3px] sm:right-[-30px] md:top-[0px] md:right-[-30px] lg:top-0 lg:right-[90px] text-[#FFF] text-[40px] font-bold cursor-pointer">
                    <img src={modalclosewhite} alt="Close" className="w-full h-full" />
                </button>
                <div className="aspect-w-16 aspect-h-9 flex justify-center">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Crew;
